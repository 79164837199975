$ui-blue-1: #0082C3;
$ui-blue-2: #0F79B5;
$ui-blue-3: #004061;
$ui-gray-0: #FFFFFF;
$ui-gray-0-fade: rgba(255, 255, 255, 0.7);
$ui-gray-1: #F5F5F6;
$ui-gray-2: #E9EBED;
$ui-gray-3: #B6B6B9;
$ui-gray-4: #6A6B6D;
$ui-gray-5: #262628;
$ui-green-1: #02BE8A;
$ui-green-2: #30A47D;
$ui-orange: #FE5800;
$ui-red: #E53935;
$ui-yellow: #FFEA28;

$primary: $ui-blue-1;
$info: $ui-blue-2;
$success: $ui-green-1;
$warning: $ui-orange;
$warning-invert: $ui-gray-0;
$danger: $ui-red;

$tooltip-max-width: 12rem;

$table-striped-row-even-background-color: $ui-gray-1;
$table-striped-row-even-hover-background-color: $ui-gray-2;
