@import "~_variables";
@import '~bulma/bulma';
@import '~bulma-extensions/src/sass/index';
@import '~flag-icon-css/css/flag-icon.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.is-clickable {
  cursor: pointer;
}

.is-not-clickable {
  cursor: not-allowed;
}

#root{
  top: 60px;
  position: absolute;
  right: 0;
  left: 0;
}

/**
 * Navbar
 */
.navbar {
  background-color: $primary !important;
}
.navbar-end{
  display: flex;
  flex-direction: column;
  align-items: flex-end !important;
}
.navbar-menu {
  background-color: $primary !important;
}
.navbar-item {
  background-color: $primary !important;
  display: flex !important;
  padding: 0 !important;
}
.navbar  {
  color: $ui-gray-0-fade !important;
}
.navbar .is-active {
  color: $ui-gray-0 !important;
}
.navbar-item > a {
  color: $ui-gray-0-fade !important;
  width: 100%;
  padding: 0.75rem;
}
.navbar-item.is-active > a {
  color: $ui-gray-0 !important;
}

/**
 * ADMIN - BATCH
 */
.admin-batch-last-executions span.batch-quick-relaunch {
  margin-left: 10px;
}

/**
 * ADMIN - ACCESS MANAGEMENT
 */
.admin-access-management .property-label {
  font-weight: bold;
  padding-right: 5px;
}

.admin-access-management .property-label-admin {
  padding-top: 4px;
}

.admin-access-management .property-label-filter {
  padding-bottom: 10px;
  display: block;
}

/**
 * Transaction analyzer
 */
.transaction-history-block {
  display: flex;
  padding: 32px 0;
}

.transaction-history-block > div {
  flex: 0 0 auto;
  width: 20%;
}

.transaction-history-block > div ul {
  width: 80%;
  margin: 0 auto;
  border: 1px solid #eee;
  padding: 16px;
  list-style: none;
}

.transaction-history-block > div + div {
  flex: 0 0 auto;
  width: 80%;
}

.textarea.json{
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 0.85rem;
}

/**
 * Mean of payment
 */
.is-compact.field:not(:last-child){
  margin-bottom: 0;
}
.is-compact.label:not(:last-child){
  margin-bottom: 0;
}

.switch[type="checkbox"].is-warning + label::before, .switch[type="checkbox"].is-warning + label::before {
  background: $warning;
}

.is-horizontal .label:not(:last-child){
  margin-bottom: 0;
}

.toggle.label:not(:last-child){
  margin-bottom: 0;
}

.mop-treeview-container {
  position: sticky;
  top: 0;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 300px;
  height: calc(100vh - 86px);
  overflow: auto;
}

.mop-list,
.mop-configuration {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 316px);
  margin-left: 16px;
}

.mop-list {
  margin-top: 30px;
  overflow: auto;
  height: calc(100vh - 116px);
}

.mop-list div.mop-list-title {
  width: calc(100% - 330px);
  position: fixed;
  top: 60px;
  line-height: 40px;
  background-color: #f9f9f9;
  padding-left: 16px;
  z-index: 1;
  margin-left: -16px;
}

.mop-list div.mop-list-title h2 + h2 {
  display: flex;
  justify-content: space-between;
}

.mop-list ul.mop-list-mean-of-payment {
  margin: 0;
  padding: 0px 4px;
}

.mop-list ul.mop-list-mean-of-payment li {
  list-style: none;
  padding-bottom: 16px;
}

.mop-configuration .mop-attribute {
  min-height: 30px;
}

.mop-configuration .mop-attribute-compact {
  margin: 5px 0;
}

/**
 * Form widget
 */
.widget-label-radio {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}

.widget-hr-radio {
  border-color: rgba(224, 224, 224, 0.3);
  float: left;
  width: 70%;
}

/* Label Form DatePicker  */
.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}

.input.has-text-left {
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.input.has-text-right {
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.control.has-icons-left .icon.is-small, .control.has-icons-right .icon.is-small {
  font-size: 0.75rem!important;
}

.date-picker.popper {
  z-index: 100;
  width: max-content;
}

// Bulma specific
.buttons {
  flex-wrap: unset !important;
}

.field.has-addons .control:not(:last-child) {
  margin-right: unset !important;
}

.steps.is-small .step-item .step-details .step-title, .steps.is-small {
  font-size: 0.5rem;
}

.steps .step-item.is-separated::before, .steps .step-item.is-separated:not(:first-child)::before {
  display: none;
}

.is-checkradio[type="checkbox"].is-small.has-big-checkradio + label::after, .is-checkradio[type="checkbox"].is-small.has-big-checkradio + label::after {
  width: 0.28125rem;
  height: 0.45rem;
  top: 0.40375rem;
  left: 0.60rem;
}

.is-checkradio[type="checkbox"].is-small.has-big-checkradio.inset-1 + label::after, .is-checkradio[type="checkbox"].is-small.has-big-checkradio.inset-1 + label::after {
  left: 1.60rem;
}

.is-checkradio[type="checkbox"].is-small.has-big-checkradio.inset-1 + label::before, .is-checkradio[type="checkbox"].is-small.has-big-checkradio.inset-1 + label::before {
  left: 1rem;
}

.is-checkradio[type="checkbox"].is-small.has-big-checkradio.inset-2 + label::after, .is-checkradio[type="checkbox"].is-small.has-big-checkradio.inset-2 + label::after {
  left: 2.60rem;
}

.is-checkradio[type="checkbox"].is-small.has-big-checkradio.inset-2 + label::before, .is-checkradio[type="checkbox"].is-small.has-big-checkradio.inset-2 + label::before {
  left: 2rem;
}

.is-checkradio[type="checkbox"].is-small.has-big-checkradio + label::before, .is-checkradio[type="checkbox"].is-small.has-big-checkradio + label::before {
  width: 1.5rem;
  height: 1.5rem;
}

// Left panel
.displayer .button:hover {
  left: unset !important;
}

// Notifications
.notification.onepay:not(:last-child) {
  margin: 2px;
  box-shadow: -1px -1px 1px -1px rgba(0, 0, 0, 0.7) inset;
}

.notifications {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 33%;
  min-width: 250px;
  z-index: 110;
}

.notification-enter {
  opacity: 0.01;
  -webkit-animation: slide-fwd-left 0.45s cubic-bezier(10, 0.460, 0.450, 0.940) both;
  animation: slide-fwd-left 0.45s cubic-bezier(10, 0.460, 0.450, 0.940) both;
}

.notification-enter.notification-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.notification-leave {
  opacity: 1;
  -webkit-animation: slide-bck-right 0.45s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: slide-bck-right 0.45s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.notification-leave.notification-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@-webkit-keyframes slide-fwd-left {
  0% {
    -webkit-transform: translateZ(0) translateX(-100px);
    transform: translateZ(0) translateX(-100px);
  }
  100% {
    -webkit-transform: translateZ(160px) translateX(0);
    transform: translateZ(160px) translateX(0);
  }
}

@keyframes slide-fwd-left {
  0% {
    -webkit-transform: translateZ(0) translateX(-100px);
    transform: translateZ(0px) translateX(-100px);
  }
  100% {
    -webkit-transform: translateZ(160px) translateX(0);
    transform: translateZ(160px) translateX(0px);
  }
}

@-webkit-keyframes slide-bck-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateX(200px);
    transform: translateZ(-400px) translateX(200px);
  }
}

@keyframes slide-bck-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateX(200px);
    transform: translateZ(-400px) translateX(200px);
  }
}

// jsoneditor
.form-all-json > div > .jsoneditor {
  height: calc(100vh / 3 * 2) !important;
}

.form-min-json > div > .jsoneditor {
  height: calc(100vh / 2 ) !important;
}

.jsoneditor-transform, .jsoneditor-repair, .jsoneditor-poweredBy {
  display: none;
}

.is-fixed-bottom-mop-tree {
  position: fixed !important;
  bottom: 20px;
  left: 300px;
  height: 44px!important;
}
